import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Шеф за 30 днів
			</title>
			<meta name={"description"} content={"Освоюй мистецтво кулінарії"} />
			<meta property={"og:title"} content={"Шеф за 30 днів"} />
			<meta property={"og:description"} content={"Освоюй мистецтво кулінарії"} />
			<meta property={"og:image"} content={"https://qivexsale.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qivexsale.com/img/4047768.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qivexsale.com/img/4047768.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qivexsale.com/img/4047768.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qivexsale.com/img/4047768.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="120px 0 130px 0"
			lg-padding="80px 0 90px 0"
			quarkly-title="Stages/Steps-5"
			color="--darkL2"
			background="--color-primary"
		>
			<Text
				margin="0px 0px 40px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--dark"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Створіть свою кулінарну історію
			</Text>
			<Text
				margin="0 0"
				font="normal 400 26px/1.5 --fontFamily-sansHelvetica"
				color="--dark"
				text-align="center"
				border-color="#7a7c7f"
				padding="0px 50px 50px 50px"
			>
				Ми завжди відкриті до ваших питань та побажань. Зв'яжіться з нами:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-light"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Icon
							category="md"
							icon={MdLocationOn}
							size="34px"
							color="--darkL2"
							padding="5px 5px 5px 5px"
						/>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px -20px" padding="0px 0px 0px 20px">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--dark"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Адреса
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 26px/1.5 --fontFamily-sansHelvetica"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							пр. Металургів, 6, офіс 25, м. Запоріжжя
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-light"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Icon
							category="md"
							icon={MdPhone}
							padding="5px 5px 5px 5px"
							size="34px"
							color="--darkL2"
						/>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px -20px" padding="0px 0px 0px 20px">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--dark"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Телефон
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 26px/1.5 --fontFamily-sansHelvetica"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							097 444 9707
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-light"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Icon
							category="md"
							icon={MdMail}
							padding="5px 5px 5px 5px"
							size="34px"
							color="--darkL2"
						/>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px -20px" padding="0px 0px 0px 20px">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--dark"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Email
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 26px/1.5 --fontFamily-sansHelvetica"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							contact@qivexsale.com
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});